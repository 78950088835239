<template>
  <div>
    <div>
      <vxe-table
          :align="allAlign"
          show-overflow
          auto-resize
          height="350"
          :data="qrmain">
        <vxe-column type="seq" title="序号" width="60"></vxe-column>
        <vxe-column field="qrmesage" width="900" title="二维码标题"></vxe-column>
        <vxe-column title="操作" width="400" show-overflow>
          <template #default="{ row }">
            <vxe-button size="mini" status="primary" @click="getQrCodeMx(row)">查看明细</vxe-button>
            <vxe-button size="mini" status="primary" @click="getqrcode(row)">查看二维码</vxe-button>
            <vxe-button size="mini" status="danger" @click="changeqrcode(row)">更换内容</vxe-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
<!--    分页-->
    <div style="float: right;margin-top: 20px;">
      <el-pagination v-model:currentPage="pagenum"
                     :small="true"
                     background layout="prev, pager, next" :total="totals">
      </el-pagination>
    </div>
    <!--    二维码弹窗-->
    <div>
      <el-dialog
          v-model="dialogVisible"
          :title="titlemian"
          width="60%"
          :center="true"
          @close="clearqr"
      >
        <div style="margin-left: 30%">
          <div id="qrcode" ref="qrCodeUrl"></div>
        </div>
      </el-dialog>
    </div>
<!--    查看明细弹窗-->
    <div>
      <el-dialog
          v-model="dialogVisibleMx"
          :title="titlemian"
      >
        <div style="text-align: center;padding-left: 50px;padding-right: 50px">
          <p style="font-size: 25px;word-wrap: break-word">
            {{ dataScanf.maintitle }}
          </p>
        </div>
        <div v-for="file in dataScanf.imgs" style="text-align: center">
          <div v-if="file.substring(file.length - 3, file.length) !== 'mp4'" @dblclick="dbclicks(file)">
            <img :src="file" alt="" style="width: 350px"/>
          </div>
          <div v-if="file.substring(file.length - 3, file.length) === 'mp4'" @dblclick="dbclicks(file)">
            <video width="350" controls>
              <source :src="file" type="video/mp4">
              <object :data="file" width="350">
                <embed :src="file" width="350">
              </object>
            </video>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="delqrcode">删除</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
<!--    删除-->
    <div>
      <el-dialog v-model="dialogDelFile" title="请确认是否删除">
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogDelFile = false">取消</el-button>
        <el-button type="primary" @click="delfiles">确定</el-button>
      </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import QRCode from "qrcodejs2";
import { ElMessage } from 'element-plus'

export default {
  inject: ['reload'],
  name: "selqrcode",
  data(){
    return{
      allAlign: 'center',
      pagenum: 1,
      qrmain: [],
      totals: 0,
      dialogVisible: false,
      titlemian: '',
      dialogVisibleMx: false,
      dataScanf: [],
      mainid: '',
      delfile: '',
      dialogDelFile: false,
      mxrow: ''
    }
  },
  computed: {
    ...mapGetters(['GET_USERID']),
    ...mapGetters(['GET_BDST'])
  },
  created() {
    this.getQrMain();
  },
  methods:{
    getQrMain(){
      this.axios.post('/qrcodemain/getqrmain', (response) => {
        this.totals = response.obj.pages * 10;
        this.qrmain = response.obj.records;
      },{
        userid: this.GET_USERID,
        bdst: this.GET_BDST,
        pageCount: 1
      })
    },
    creatQrCode(val) {
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: 'https://www.cr12cz.cn/mobilsel?qrcodeid=' + val, // 需要转换为二维码的内容
        //text: 'http://182.92.228.212/czsc/#/mobilsel?qrcodeid=' + val, // 需要转换为二维码的内容
        width: 500,
        height: 500,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      });
    },
    getqrcode(row){
      this.titlemian = row.qrmesage + '的二维码';
      this.dialogVisible = true;
      setTimeout(() => { this.creatQrCode(row.id) }, 100)
    },
    clearqr(){
      const codeHtml = document.getElementById("qrcode");
      codeHtml.innerHTML = "";
    },
    getQrCodeMx(row){
      this.mxrow = row;
      this.mainid = row.id;
      this.titlemian = row.qrmesage + '的明细';
      this.dialogVisibleMx = true;
      this.axios.post('/qrcodemain/getone', (response) => {
        console.log(response.obj)
        this.dataScanf = response.obj;
      },{
        mainid: row.id
      })
    },
    delqrcode(){
      this.axios.post('/qrcodemain/delcode', (response) => {
        if (response.obj){
          this.reload();
          ElMessage({
            message: '删除成功',
            type: 'success',
          })
        }
      },{
        id: this.mainid
      })
    },
    dbclicks(file){
      this.delfile = file;
      this.dialogDelFile = true;
    },
    delfiles(){
      this.axios.post('/qrcodemain/delfile', (response) => {
        if (response.obj){
          this.dialogDelFile = false;
          this.getQrCodeMx(this.mxrow)
        }else {
          ElMessage.error('删除失败，请联系管理员')
        }
      },{
        filename: this.delfile
      })
    },
    changeqrcode(row){
      this.$router.push({
        name: 'Addqr',
        params: {
          title: row.qrmesage,
          id: row.id,
          key: '123123123'
        }
      })
    }
  },
  watch: {
    pagenum(){
      this.axios.post('/qrcodemain/getqrmain', (response) => {
        this.totals = response.obj.pages * 10;
        this.qrmain = response.obj.records;
      },{
        userid: this.GET_USERID,
        bdst: this.GET_BDST,
        pageCount: this.pagenum
      })
    }
  }
}
</script>

<style scoped>

</style>